import { openModal } from '../../modals/framework/store/modal-actions';
import { MODAL_TYPE_POST_SETTINGS } from '@wix/communities-blog-client-common';
import { getCategories } from '../../selectors/categories-selectors';
import { getSiteInfo } from '../../selectors/site-info-selectors';
import { getWriters } from '../../selectors/writers-selectors';

export const openPostSettings = ({
  activeTab,
  formInitialValues,
  postValues,
  focusOnCloseId,
  isPublished,
  postId,
  canPublish,
}) => (dispatch, getState) =>
  dispatch(
    openModal(
      MODAL_TYPE_POST_SETTINGS,
      {
        activeTab,
        canPublish,
        categories: getCategories(getState()),
        formInitialValues,
        isPublished,
        postId,
        postValues,
        siteInfo: getSiteInfo(getState()),
        writers: getWriters(getState()),
      },
      focusOnCloseId,
    ),
  );
