import { createAction } from '@wix/communities-blog-client-common';
import { sanitizeContent } from '@wix/communities-blog-universal/dist/src/utils/sanitize-content';
import { extractHashtagsFromContent } from '../../services/hashtag-utils';
import { createPromisifiedAction } from '../../actions-promisifier/create-promisified-action';

export const UPDATE_COMMENT_REQUEST = 'comment/UPDATE_REQUEST';
export const UPDATE_COMMENT_SUCCESS = 'comment/UPDATE_SUCCESS';
export const UPDATE_COMMENT_FAILURE = 'comment/UPDATE_FAILURE';

export const updateCommentRequest = createAction(UPDATE_COMMENT_REQUEST);
export const updateCommentSuccess = createAction(UPDATE_COMMENT_SUCCESS);
export const updateCommentFailure = createAction(UPDATE_COMMENT_FAILURE);

export function updateComment({ _id: commentId, postId, content }) {
  const updatedComment = sanitizeContent({ content });
  return (dispatch, getState, { request }) => {
    dispatch(updateCommentRequest());

    const promise = request.patch(`/_api/posts/${postId}/comments/${commentId}`, {
      ...updatedComment,
      hashtags: extractHashtagsFromContent(updatedComment.content),
    });

    return promise
      .then(comment => dispatch(updateCommentSuccess(comment)))
      .catch(() => dispatch(updateCommentFailure()))
      .then(() => promise);
  };
}

export const updateCommentPromisified = createPromisifiedAction(
  updateComment,
  () => null,
  response => response.status,
);
