import { isNumber, pick } from 'lodash';

import { COVER_TYPE_COLOR } from '@wix/communities-blog-client-common';
import { getHighestRank, getCategory } from '../selectors/categories-selectors';

const FORWARDED_FIELDS = ['overlayColor', 'backgroundColor', 'textColor'];

export const addCategoryFormFields = ({ categoryId, values, state }) => {
  const category = categoryId ? { ...getCategory(state, categoryId), ...values } : values;
  const { menuLabel, slug, label, description, cover, rank, coverType } = category;

  return {
    menuLabel,
    slug,
    label: label || menuLabel,
    description: description || '',
    cover,
    coverType: coverType || COVER_TYPE_COLOR, // COVER_TYPE_COLOR is used as a default fallback for old app settings category creation
    rank: isNumber(rank) ? rank : getHighestRank(state) + 1,
    ...pick(category, FORWARDED_FIELDS),
  };
};
