import { get } from 'lodash';

import { MODAL_TYPE_PREVIEW_POST } from '@wix/communities-blog-client-common';
import { getTopology } from '../store/topology/topology-selectors';
import { getInstance } from '../store/basic-params/basic-params-selectors';
import { openModal } from '../modals/framework/store/modal-actions';
import getPostUrl from '../services/get-post-url';

export const previewPost = postId => (dispatch, getState) => {
  const state = getState();
  const topology = getTopology(state);
  const instance = getInstance(state);
  const isBlogPublished = get(topology, 'baseUrl', false);
  const postPreviewUrl = postId && isBlogPublished ? getPostUrl(topology, [postId, 'preview', instance]) : '';

  dispatch(openModal({ type: MODAL_TYPE_PREVIEW_POST, props: { postPreviewUrl } }));
};
