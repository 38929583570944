import { MODAL_TYPE_POST_SETTINGS } from '@wix/communities-blog-client-common';
import { setActivePostSettingsTab } from '../../../common/store/tabs/set-active-tab';
import { setIsSaving } from '../../../common/store/is-saving/is-saving-actions';

export const modalDataLoaderByType = {
  [MODAL_TYPE_POST_SETTINGS]: ({ activeTab /* , categories, siteInfo, writers, canPublish, isPublished */ }) => [
    // HACK
    setActivePostSettingsTab(activeTab),
    // setCategories(categories),
    // setSiteInfo(siteInfo),
    // setWriters(writers),
    // setCanPublish(canPublish),
    // setIsPublished(isPublished),
    setIsSaving(false),
  ],
};
