import { combineReducers } from 'redux';

import commonReducers from '../../common/reducers';
import isPostInPreview from '../../common/store/is-post-in-preview/is-post-in-preview-reducer';
import lastPostSlug from './last-post-slug';
import postEditor from '../../common/store/post-editor/post-editor-reducer';
import rceSettings from './rce-settings';
import recentPostsIds from '../../recent-posts/reducers/recent-posts-ids';
import loadingBar from '../../common/store/loading-bar/loading-bar-reducer';

export default combineReducers({
  ...commonReducers,

  isPostInPreview,
  lastPostSlug,
  postEditor,
  rceSettings,
  recentPostsIds,
  loadingBar,
});
