import { createAction } from '@wix/communities-blog-client-common';

export const FETCH_WRITERS_REQUEST = 'writers/FETCH_REQUEST';
export const FETCH_WRITERS_SUCCESS = 'writers/FETCH_SUCCESS';
export const FETCH_WRITERS_FAILURE = 'writers/FETCH_FAILURE';

export const fetchWritersRequest = createAction(FETCH_WRITERS_REQUEST);
export const fetchWritersSuccess = createAction(FETCH_WRITERS_SUCCESS);
export const fetchWritersFailure = createAction(FETCH_WRITERS_FAILURE);

export const fetchWriters = () => (dispatch, _getState, { request }) => {
  dispatch(fetchWritersRequest());

  return request('/_api/users?roles=admin,writer,editor')
    .then(writers => dispatch(fetchWritersSuccess(writers)))
    .catch(() => dispatch(fetchWritersFailure()));
};

export const SET_WRITERS = 'writers/SET';

export const setWriters = createAction(SET_WRITERS);
