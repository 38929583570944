import uri from 'urijs';
import {
  createAction,
  SECTION_CATEGORY,
  ENTITY_TYPE_POSTS,
  BLOG_HEADER_CURSOR,
} from '@wix/communities-blog-client-common';
import { buildPaginationRequestParams, getTotalResults } from '../services/pagination';
import { getCategoryIds } from '../selectors/categories-selectors';
import { normalizePosts } from '../services/post-utils';
import { getPageSize, getCursor } from '../selectors/pagination-selectors';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';
import { getHeader } from '../services/get-header';

export const FETCH_TAG_POSTS_REQUEST = 'tagPosts/FETCH_REQUEST';
export const FETCH_TAG_POSTS_SUCCESS = 'tagPosts/FETCH_SUCCESS';
export const FETCH_TAG_POSTS_FAILURE = 'tagPosts/FETCH_FAILURE';

export const fetchTagPostsRequest = createAction(FETCH_TAG_POSTS_REQUEST);
export const fetchTagPostsSuccess = createAction(
  FETCH_TAG_POSTS_SUCCESS,
  payload => payload,
  (payload, meta) => meta,
);
export const fetchTagPostsFailure = createAction(FETCH_TAG_POSTS_FAILURE);

export function fetchTagPosts({ tagId, page = 1, pageSize: defaultPageSize, featuredOnly, excludeContent }) {
  return (dispatch, getState, { request }) => {
    dispatch(fetchTagPostsRequest({ tagId, page }));

    const cursor = getCursor(getState(), ENTITY_TYPE_POSTS);
    const pageSize = getPageSize(getState(), { overrideSettingsPageSize: defaultPageSize, section: SECTION_CATEGORY });
    const params = buildPaginationRequestParams(page, pageSize, cursor);

    const promise = request(uri('/_api/posts').query({ tagIds: tagId, featuredOnly, excludeContent, ...params }), {
      parseHeaders: true,
    });

    return promise
      .then(({ body = [], headers = {} }) =>
        dispatch(
          fetchTagPostsSuccess(normalizePosts({ posts: body, blogCategoryIds: getCategoryIds(getState()) }), {
            tagId,
            page,
            entityCount: getTotalResults(headers),
            pageSize,
            cursor: getHeader(headers, BLOG_HEADER_CURSOR),
          }),
        ),
      )
      .catch(({ status }) => dispatch(fetchTagPostsFailure({ error: { status }, tagId, page })))
      .then(() => promise);
  };
}

export const fetchTagPostsPromisified = createPromisifiedAction(
  fetchTagPosts,
  () => null,
  response => response.status,
);
