import { MEDIA_TYPE_IMAGE, requestCredentials, uploadFile } from '@wix/communities-blog-client-common';

import { setIsLoading } from '../store/is-loading/is-loading-actions';
import { uploadStarted } from './upload-started';
import { uploadEnded } from './upload-ended';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';

export const uploadImage = (file, imageData) => (dispatch, getState, { request }) => {
  const id = new Date().getTime();
  const stopLoading = data => {
    dispatch(setIsLoading('upload', id, false));
    return data;
  };
  dispatch(setIsLoading('upload', id, true));

  dispatch(uploadStarted({ type: MEDIA_TYPE_IMAGE }));
  return requestCredentials(request, MEDIA_TYPE_IMAGE)
    .then(
      credentials =>
        uploadFile({
          file: file.type !== 'image/gif' ? imageData.dataUrl : file,
          name: file.name,
          type: MEDIA_TYPE_IMAGE,
          credentials,
        }).promise,
    )
    .then(uploadedImageData => {
      dispatch(uploadEnded({ type: MEDIA_TYPE_IMAGE }));
      return uploadedImageData;
    })
    .then(stopLoading)
    .catch(stopLoading);
};

export const uploadImagePromisified = createPromisifiedAction(
  uploadImage,
  imageData => imageData,
  response => response.status,
);
