import { BUTTON_CLICKED } from '../../actions/button-clicked';
import { BUTTON_CREATE_POST, BUTTON_SHARE_POST_MODAL, BUTTON_SHARE_POST } from '../bi-buttons';
import { getCurrentUserRole } from '@wix/communities-blog-client-common';

const handler = (action, state) => {
  switch (action.payload.action) {
    case BUTTON_CREATE_POST:
      return {
        evid: 208,
        eventMeta: {
          description: 'write new post click',
        },
      };
    case BUTTON_SHARE_POST_MODAL:
      return {
        evid: 213,
        eventMeta: {
          description: 'open share modal',
        },
        post_id: action.payload.postId,
        role: getCurrentUserRole(state),
      };
    case BUTTON_SHARE_POST:
      return {
        evid: 214,
        eventMeta: {
          description: 'share post',
        },
        shareType: action.payload.shareType,
        post_id: action.payload.postId,
        role: getCurrentUserRole(state),
      };
    default:
      return;
  }
};

export const uouButtonClickedEvent = { [BUTTON_CLICKED]: handler };
