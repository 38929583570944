import { compact } from 'lodash';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createRequestWithBaseUrl, appendOriginInBackend } from '../../common/controller/helpers';
import commonReducers from '../../common/reducers';
import blogStatsReducer from '../../common/store/blog-stats/blog-stats-reducer';
import { appUouBiMiddleware } from '../../common/bi-events/init-middleware';
import { eventMap } from '../bi-events/uou-events';

export function createReduxStore({ appParams, wixCodeApi, compId, fedopsLogger, isEditor, isPreview, platformAPIs }) {
  const p = {};
  const createRequest = createRequestWithBaseUrl({ wixCodeApi, getStore: () => p.store });
  const { apiBaseUrlClient, apiPlatformizedBaseUrlClient, apiExperimentsBaseUrlClient } = appParams.baseUrls;

  const platformizedRequestUrl = appendOriginInBackend({ wixCodeApi, baseUrl: apiPlatformizedBaseUrlClient });
  const platformizedRequest = createRequest(platformizedRequestUrl);

  const experimentsRequestUrl = appendOriginInBackend({ wixCodeApi, baseUrl: apiExperimentsBaseUrlClient });
  const experimentsRequest = createRequest(experimentsRequestUrl);

  const blogApiBaseUrl = isEditor || isPreview ? apiBaseUrlClient : `${wixCodeApi.location.baseUrl}${apiBaseUrlClient}`;
  const request = createRequest(blogApiBaseUrl);

  const biMiddleware = !isEditor && appUouBiMiddleware({ platformAPIs, instanceId: appParams.instanceId, eventMap });

  const middleware = compact([
    thunk.withExtraArgument({
      request,
      platformizedRequest,
      experimentsRequest,
      wixCodeApi,
      compId,
      appParams,
      fedopsLogger,
    }),
    biMiddleware,
  ]);

  const reducers = combineReducers({ ...commonReducers, blogStats: blogStatsReducer });

  p.store = createStore(reducers, undefined, applyMiddleware(...middleware));

  return p.store;
}
