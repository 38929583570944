import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createRequestWithBaseUrl, appendOriginInBackend } from '../../common/controller/helpers';
import { wixDataReducer } from '@wix/communities-blog-client-common';
import commonReducers from '../../common/reducers';
import postCount from '../reducers/post-count';
import categories from '../../common/store/categories/categories-reducer';
import postStatsByDate from '../../common/store/post-stats-by-date/post-stats-by-date-reducer';

export function createReduxStore({ appParams, wixCodeApi, compId, fedopsLogger, isEditor, isPreview }) {
  const p = {};
  const createRequest = createRequestWithBaseUrl({ wixCodeApi, getStore: () => p.store });
  const { apiBaseUrlClient, apiPlatformizedBaseUrlClient, apiExperimentsBaseUrlClient } = appParams.baseUrls;

  const platformizedRequestUrl = appendOriginInBackend({ wixCodeApi, baseUrl: apiPlatformizedBaseUrlClient });
  const platformizedRequest = createRequest(platformizedRequestUrl);

  const experimentsRequestUrl = appendOriginInBackend({ wixCodeApi, baseUrl: apiExperimentsBaseUrlClient });
  const experimentsRequest = createRequest(experimentsRequestUrl);

  const blogApiBaseUrl = isEditor || isPreview ? apiBaseUrlClient : `${wixCodeApi.location.baseUrl}${apiBaseUrlClient}`;
  const request = createRequest(blogApiBaseUrl);

  const middleware = [
    thunk.withExtraArgument({
      request,
      platformizedRequest,
      experimentsRequest,
      wixCodeApi,
      compId,
      appParams,
      fedopsLogger,
    }),
  ];

  const reducers = combineReducers({
    ...commonReducers,
    postStatsByDate,
    wixData: wixDataReducer,
    postCount,
    categories,
  });

  p.store = createStore(reducers, undefined, applyMiddleware(...middleware));

  return p.store;
}
