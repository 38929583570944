import uri from 'urijs';

import {
  createAction,
  ENTITY_TYPE_MANAGE_POSTS_PUBLISHED,
  ENTITY_TYPE_MANAGE_POSTS_DRAFTS,
  ENTITY_TYPE_POSTS,
} from '@wix/communities-blog-client-common';
import { buildPaginationRequestParams, getTotalResults } from '../services/pagination';
import { getCategoryIds } from '../selectors/categories-selectors';
import { normalizePosts } from '../services/post-utils';
import { POST_STATUS } from '@wix/communities-blog-universal/dist/src/constants/post';
import { getPageSize } from '../selectors/pagination-selectors';

export const FETCH_POSTS_REQUEST = 'posts/FETCH_REQUEST';
export const FETCH_POSTS_SUCCESS = 'posts/FETCH_SUCCESS';
export const FETCH_POSTS_FAILURE = 'posts/FETCH_FAILURE';

export const fetchPostsRequest = createAction(FETCH_POSTS_REQUEST);
export const fetchPostsSuccess = createAction(
  FETCH_POSTS_SUCCESS,
  payload => payload,
  (payload, meta) => meta,
);
export const fetchPostsFailure = createAction(FETCH_POSTS_FAILURE);

export const fetchPosts = ({
  page = 1,
  pageSize: defaultPageSize,
  entityType = ENTITY_TYPE_POSTS,
  fixedPageSize,
  ...params
} = {}) => (dispatch, getState, { request }) => {
  dispatch(fetchPostsRequest({ entityType, page }));

  const pageSize = fixedPageSize || getPageSize(getState(), { overrideSettingsPageSize: defaultPageSize });
  const paginationParams = buildPaginationRequestParams(page, pageSize);
  const query = {
    ...paginationParams,
    ...params,
  };
  const promise = request(uri('/_api/posts').query(query), { parseHeaders: true });

  return promise
    .then(({ body = [], headers = {} }) =>
      dispatch(
        fetchPostsSuccess(normalizePosts({ posts: body, blogCategoryIds: getCategoryIds(getState()) }), {
          entityCount: getTotalResults(headers),
          entityType,
          page,
          pageSize,
        }),
      ),
    )
    .catch(() => dispatch(fetchPostsFailure({ entityType, page })))
    .then(() => promise);
};

export const fetchPublishedPosts = (params = {}) =>
  fetchPosts({ status: POST_STATUS.published, entityType: ENTITY_TYPE_MANAGE_POSTS_PUBLISHED, ...params });

export const fetchDrafts = (params = {}) =>
  fetchPosts({ status: POST_STATUS.unpublished, entityType: ENTITY_TYPE_MANAGE_POSTS_DRAFTS, ...params });
