import { fetchSearchWithCancel } from './fetch-search';
import { createAction } from '@wix/communities-blog-client-common';

export const SEARCH = 'search/SEARCH';
export const SEARCH_QUERY_SEND = 'search/QUERY_SEND';
export const SEARCH_CLEAR_RESULTS = 'search/CLEAR_RESULTS';

export const MIN_QUERY_LENGTH = 2;

export const clearSearchResults = createAction(SEARCH_CLEAR_RESULTS);

export default function search({ query = '', sort, minQueryLength = MIN_QUERY_LENGTH }) {
  return dispatch => {
    dispatch({
      type: SEARCH,
      payload: query,
    });

    dispatch(fetchSearchWithCancel({ query, sort, debounce: 300, minQueryLength }));
    if (query.length < minQueryLength) {
      dispatch(clearSearchResults());
    }
  };
}
