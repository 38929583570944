import { once, noop } from 'lodash';
import { ARCHIVE_PARAMS } from '@wix/communities-blog-client-common';
import { createNoopController } from '../../viewer-script/controllers/createNoopController';
import { validateLanguage } from '../../common/services/validate-language';
import { createLogger, getEnvironment } from '../../common/controller/helpers';
import { createReduxStore } from './create-redux-store';
import { handleProvisioning } from '../../common/services/handle-provisioning';
import { handleError } from '../../common/store/debug-state/handle-error';
import {
  initializeActions,
  initializePromisifiedActions,
  initializeStoreBaseData,
  refreshDataOnLogin,
} from './init-actions';
import { ARCHIVE_WIDGET_NAME } from '../../viewer-script/constants/widgets';
import { createMonitoring } from '../../common/services/create-monitoring';
import { subscribeToChange } from '../../common/services/state-optimizer';
import { simulateControllerError } from '../../common/services/simulate-error';
import { isInWorker } from '../../common/services/is-in-worker';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import listenToEditModeChange from '../../common/services/listen-to-edit-mode-change';
import listenToSettingsChange from '../../common/services/listen-to-settings-change';
import { getInitialStateVersions } from '../../common/services/state-optimizer/change-detector';
import { fetchPostStatsByDate } from '../../common/store/post-stats-by-date/post-stats-by-date-actions';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';

const isProduction = process.env.NODE_ENV === 'production';
let isControllerInitialized = false;

export const createArchiveController = (
  { appParams, compId, config, setProps, wixCodeApi, platformAPIs, type },
  allCtrls,
) => {
  const { isEditor, isPreview, isSSR, isDebug } = getEnvironment(wixCodeApi);

  // there is a bug in viewerScriptWrapper where it initialized controller again when going into preview
  if ((isEditor || isPreview) && (isControllerInitialized || isInWorker())) {
    return createNoopController();
  } else {
    isControllerInitialized = true;
  }

  const { captureToSentry, fedopsLogger } = createMonitoring({
    origin: `app-worker-${ARCHIVE_WIDGET_NAME}`,
    platformAPIs,
    appParams,
    isSSR,
    isDebug,
    type,
  });

  fedopsLogger.appLoadStarted();
  const fedopsAppLoaded = once(() => fedopsLogger.appLoaded());

  const language = validateLanguage(wixCodeApi.site.language);

  const log = createLogger(isDebug, isProduction);

  log('createArchiveController', { appParams, allCtrls, wixCodeApi, isSSR, language });

  const pageReady = async () => {
    let store;
    try {
      log('createArchiveController.pageReady -> start');

      simulateControllerError(wixCodeApi, 'archive.pageReady');

      store = createReduxStore({
        appParams,
        wixCodeApi,
        compId,
        fedopsLogger,
        isEditor,
        isPreview,
      });
      const actions = initializeActions({
        wixCodeApi,
        store,
        fedopsLogger,
        fedopsAppLoaded,
      });
      const actionsPromisified = initializePromisifiedActions({ wixCodeApi, compId, store });

      await initializeStoreBaseData({
        wixCodeApi,
        appParams,
        store,
        language,
        platformAPIs,
        config,
        bundleName: 'archive-widget',
        fedopsAppName: 'communities-blog-archive-widget',
        translationsName: 'archive-widget',
      });

      const monthsDisplayLimit =
        isEditor || isPreview
          ? ARCHIVE_PARAMS.monthsDisplayLimit.maxValue
          : getAppSettingsValue({
              state: store.getState(),
              key: ARCHIVE_PARAMS.monthsDisplayLimit.appSettingsPath,
              fallback: ARCHIVE_PARAMS.monthsDisplayLimit.defaultValue,
            });
      await store.dispatch(fetchPostStatsByDate(monthsDisplayLimit));

      log('createArchiveController.pageReady -> done');
      const state = store.getState();

      const stateVersions = getInitialStateVersions(state);
      setProps({
        state,
        stateVersions,
        actions,
        actionsPromisified,
        cssBaseUrl: appParams.baseUrls.staticsBaseUrl,
        isSSR,
        isRTL: isRtlLanguage(language),
      });

      if (isSSR) {
        fedopsAppLoaded();
      }

      if (isEditor || isPreview) {
        listenToEditModeChange(store);
        listenToSettingsChange(store);
      }

      refreshDataOnLogin({ wixCodeApi, store, router: undefined, pathname: undefined, doRouting: noop });
      subscribeToChange(store, stateVersions, setProps);
    } catch (error) {
      handleError({
        controller: ARCHIVE_WIDGET_NAME,
        store,
        setProps,
        appParams,
        captureToSentry,
        isDebug,
        isSSR,
      })(error);
    }
  };

  return Promise.resolve({
    pageReady: () =>
      isEditor ? handleProvisioning(appParams, fedopsLogger, wixCodeApi, setProps, pageReady) : pageReady(),
    exports: () => {},
  }).catch(console.error);
};
