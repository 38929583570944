import { createAction } from '@wix/communities-blog-client-common';

export const SET_POST_EDITOR_POST = 'postEditor/SET_POST';
export const UPDATE_POST_EDITOR_POST = 'postEditor/UPDATE_POST';
export const CLEAN_POST_EDITOR_STATE = 'postEditor/CLEAN_STATE';

export const cleanPostEditorState = createAction(CLEAN_POST_EDITOR_STATE);

const getDraftKey = post => post._id || String(new Date().getTime());

export const setPostEditorPost = createAction(SET_POST_EDITOR_POST, (post = {}) => ({
  post,
  draftKey: getDraftKey(post),
  initialDraftKey: getDraftKey(post),
  initialPost: post,
}));

export const updatePostEditorPost = createAction(UPDATE_POST_EDITOR_POST, (post = {}) => ({
  post,
  draftKey: post._id,
}));
