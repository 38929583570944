import { get } from 'lodash';
import uri from 'urijs';
import { createAction } from '@wix/communities-blog-client-common';

export const FETCH_EXPERIMENTS_REQUEST = 'experiments/FETCH_EXPERIMENTS_REQUEST';
export const FETCH_EXPERIMENTS_SUCCESS = 'experiments/FETCH_EXPERIMENTS_SUCCESS';

export const fetchExperimentsRequest = createAction(FETCH_EXPERIMENTS_REQUEST);
export const fetchExperimentsSuccess = createAction(FETCH_EXPERIMENTS_SUCCESS);

const EXPERIMENTS_SCOPE = 'wix-social-blog';

export function fetchExperiments(metaSiteId) {
  return async (dispatch, _getState, { experimentsRequest }) => {
    dispatch(fetchExperimentsRequest());

    try {
      const body = await experimentsRequest(
        uri('/v1/laboratory/platform/conductAllInScope').query({
          scope: EXPERIMENTS_SCOPE,
          'requestContext.overrideCriteria.entityId': metaSiteId,
        }),
      );
      return dispatch(fetchExperimentsSuccess(get(body, 'values', {})));
    } catch {
      return dispatch(fetchExperimentsSuccess({}));
    }
  };
}
