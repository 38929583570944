import uri from 'urijs';
import {
  createAction,
  SECTION_CATEGORY,
  ENTITY_TYPE_POSTS,
  BLOG_HEADER_CURSOR,
} from '@wix/communities-blog-client-common';

import { buildPaginationRequestParams, getTotalResults } from '../services/pagination';
import { getCategoryIds } from '../selectors/categories-selectors';
import { normalizePosts } from '../services/post-utils';
import { getPageSize, getCursor } from '../selectors/pagination-selectors';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';
import { getHeader } from '../services/get-header';

export const FETCH_CATEGORY_POSTS_REQUEST = 'categoryPosts/FETCH_REQUEST';
export const FETCH_CATEGORY_POSTS_SUCCESS = 'categoryPosts/FETCH_SUCCESS';
export const FETCH_CATEGORY_POSTS_FAILURE = 'categoryPosts/FETCH_FAILURE';

export const fetchCategoryPostsRequest = createAction(FETCH_CATEGORY_POSTS_REQUEST);
export const fetchCategoryPostsSuccess = createAction(
  FETCH_CATEGORY_POSTS_SUCCESS,
  payload => payload,
  (payload, meta) => meta,
);
export const fetchCategoryPostsFailure = createAction(FETCH_CATEGORY_POSTS_FAILURE);

export function fetchCategoryPosts({ categoryId, page = 1, pageSize: defaultPageSize, featuredOnly, excludeContent }) {
  return (dispatch, getState, { request }) => {
    dispatch(fetchCategoryPostsRequest({ categoryId, page }));

    const categoriesCursor = getCursor(getState(), ENTITY_TYPE_POSTS);
    const pageSize = getPageSize(getState(), { overrideSettingsPageSize: defaultPageSize, section: SECTION_CATEGORY });
    const params = buildPaginationRequestParams(page, pageSize, categoriesCursor);

    const promise = request(
      uri('/_api/posts').query({ categoryIds: categoryId, featuredOnly, excludeContent, ...params }),
      {
        parseHeaders: true,
      },
    );

    return promise
      .then(({ body = [], headers = {} }) =>
        dispatch(
          fetchCategoryPostsSuccess(
            normalizePosts({ posts: body, blogCategoryIds: getCategoryIds(getState()).concat(categoryId) }),
            {
              categoryId,
              page,
              entityCount: getTotalResults(headers),
              pageSize,
              cursor: getHeader(headers, BLOG_HEADER_CURSOR),
            },
          ),
        ),
      )
      .catch(({ status }) => dispatch(fetchCategoryPostsFailure({ error: { status }, categoryId, page })))
      .then(() => promise);
  };
}

export const fetchCategoryPostsPromisified = createPromisifiedAction(
  fetchCategoryPosts,
  () => null,
  response => response.status,
);
