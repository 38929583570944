import { createAction } from '@wix/communities-blog-client-common';

export const FETCH_USER_REQUEST = 'posts/FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'posts/FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'posts/FETCH_USER_FAILURE';

export const fetchUserRequest = createAction(FETCH_USER_REQUEST);
export const fetchUserSuccess = createAction(FETCH_USER_SUCCESS);
export const fetchUserFailure = createAction(FETCH_USER_FAILURE);

export function fetchUser(userId) {
  return (dispatch, getState, { request }) => {
    dispatch(fetchUserRequest());

    const promise = request(`/_api/users/${userId}`);

    return promise.then(
      body => dispatch(fetchUserSuccess(body)),
      () => dispatch(fetchUserFailure()),
    ); // we don't returned original promise, because this fails in editor when blog is from template but not yet provisioned
  };
}
