import { EXPERIMENT_USE_CUSTOM_ROUTER } from '@wix/communities-blog-experiments';
import { createAction, isExperimentEnabled } from '@wix/communities-blog-client-common';
import wixBlogs from '@wix/communities-universal/dist/src/constants/wix-blogs';
import { getBlogFeedSectionUrl, getBlogPostPageSectionUrl } from '../../services/get-section-url';
import { getHomepage } from '../../services/get-homepage';

const { WP_BLOGS, SITE_URLS, POST_PAGE_URLS } = wixBlogs;

export const FETCH_TOPOLOGY_SUCCESS = 'topology/FETCH_SUCCESS';

export const fetchTopologySuccess = createAction(FETCH_TOPOLOGY_SUCCESS);

export const fetchTopology = instanceId => async (dispatch, getState, { wixCodeApi }) => {
  if (WP_BLOGS.includes(instanceId)) {
    return dispatch(
      fetchTopologySuccess({
        isHomePage: true,
        baseUrl: SITE_URLS[instanceId],
        sectionUrl: SITE_URLS[instanceId],
        postPageSectionUrl: POST_PAGE_URLS[instanceId],
        postPagePath: '/',
      }),
    );
  }

  const useBaseUrlAsSection = isExperimentEnabled(getState(), EXPERIMENT_USE_CUSTOM_ROUTER);
  const baseUrl = wixCodeApi.location.baseUrl;
  const [sectionUrl, postPageSectionUrl, homepage] = await Promise.all([
    getBlogFeedSectionUrl(wixCodeApi),
    getBlogPostPageSectionUrl(wixCodeApi),
    getHomepage(wixCodeApi),
  ]);

  dispatch(
    fetchTopologySuccess({
      baseUrl,
      isHomePage: homepage ? `${baseUrl}${homepage.url}` === sectionUrl : false,
      sectionUrl: useBaseUrlAsSection ? baseUrl : sectionUrl,
      postPageSectionUrl: useBaseUrlAsSection ? baseUrl : postPageSectionUrl,
      postPagePath: useBaseUrlAsSection ? '/' : postPageSectionUrl.replace(baseUrl, ''),
    }),
  );
};
