import { compact } from 'lodash';

import { openModal } from '../../common/modals/framework/store/modal-actions';
import { isAnotherWriterEditingThePost } from '@wix/communities-blog-universal/dist/src/post-utils';
import {
  MODAL_TYPE_POST_EDITOR,
  MODAL_TYPE_POST_FORM_EDIT_IN_PROGRESS,
  getCurrentUserId,
  getCurrentUser,
  getCurrentUserSiteMemberId,
} from '@wix/communities-blog-client-common';
import createPermissionHelpers from '../../common/services/create-permission-helpers';
import createPermissionChecker from '../../common/services/create-permission-checker';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';
import fetchCategories from '../../common/store/categories/fetch-categories';
import { fetchRceSettings } from '../actions/rce-settings';
import { fetchWriters } from '../../common/store/writers/writers-actions';
import { fetchPost } from '../../common/actions/fetch-post';
import { setPostEditorPost } from '../../common/store/post-editor/post-editor-actions';

export const fetchInitialPostEditorData = (state, dispatch, postSlugOrId) => {
  const { can } = createPermissionHelpers(createPermissionChecker(state, getCurrentUser(state)));
  const initialData = compact([
    dispatch(fetchRceSettings()),
    dispatch(fetchCategories()),
    can('change-owner', 'post') && dispatch(fetchWriters()),
    postSlugOrId
      ? dispatch(fetchPost(postSlugOrId, { includeDraft: true })).then(post => tryEditPost(state, dispatch, post))
      : initPostEditor(state, dispatch),
  ]);

  return Promise.all(initialData);
};

const tryEditPost = (state, dispatch, post) => {
  if (
    isAnotherWriterEditingThePost(post, getCurrentUserSiteMemberId(state)) ||
    isAnotherWriterEditingThePost(post, getCurrentUserId(state))
  ) {
    return dispatch(openModal(MODAL_TYPE_POST_FORM_EDIT_IN_PROGRESS, { postSlug: post.slug }));
  }
  return initPostEditor(state, dispatch, post);
};

const initPostEditor = (state, dispatch, post) => {
  const isMobile = getIsMobile(state);
  if (isMobile) {
    dispatch(setPostEditorPost(post));
    return dispatch(openModal(MODAL_TYPE_POST_EDITOR));
  }
  return dispatch(setPostEditorPost(post));
};
