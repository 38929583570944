import uri from 'urijs';
import { createAction } from '@wix/communities-blog-client-common';
import { getInstance, getIsDebug } from '../../controller/helpers';
import { getCurrentPage } from '../../services/get-current-page';
import { isRtlLanguage } from '../../services/is-rtl-language';
import { resolveDeviceType } from '../../services/device-type';
import { getIsCreatedWithResponsiveEditor } from '../../selectors/app-settings-selectors';

export const SET_BASIC_PARAMS = 'SET_BASIC_PARAMS';
const setBasicParamsAction = createAction(SET_BASIC_PARAMS);

export const SET_VIEW_MODE = 'SET_VIEW_MODE';
export const setViewMode = createAction(SET_VIEW_MODE);

export function setBasicParams({ viewMode, language, biPageNumber }) {
  return async (dispatch, getState, { wixCodeApi, appParams }) => {
    const showMobileView = wixCodeApi.location.url && uri(wixCodeApi.location.url).hasQuery('showMobileView', 'true');

    const isSSR = wixCodeApi.window.rendering.env === 'backend';
    const isRTL = isRtlLanguage(language);

    const apiBaseUrl = isSSR ? appParams.baseUrls.apiBaseUrl : appParams.baseUrls.apiBaseUrlClient;

    let currentPage = wixCodeApi.site.currentPage;
    if (viewMode.toLowerCase() !== 'site' && !isSSR) {
      currentPage = await getCurrentPage();
      currentPage.name = currentPage.title;
    }
    const isCreatedWithResponsiveEditor = getIsCreatedWithResponsiveEditor(getState());

    return dispatch(
      setBasicParamsAction({
        viewMode,
        instance: getInstance(wixCodeApi)(),
        formFactor: showMobileView ? 'Mobile' : resolveDeviceType(wixCodeApi, isCreatedWithResponsiveEditor),
        isSSR,
        isRTL,
        isSeo: wixCodeApi.seo.isInSEO(),
        language,
        apiBaseUrl,
        isDebug: getIsDebug(wixCodeApi),
        isProduction: process.env.NODE_ENV === 'production',
        biPageNumber,
        page: wixCodeApi.location.query.page,
        url: wixCodeApi.location.url,
        currentPage,
      }),
    );
  };
}
