import { createAction, MEDIA_TYPE_IMAGE } from '@wix/communities-blog-client-common';
import { WP_BLOGS } from '@wix/communities-universal/dist/src/constants/wix-blogs';

export const SET_ENABLE_LINKIFY = 'editorConfig/SET_ENABLE_LINKIFY';
export const SET_MEDIA_TOKEN = 'editorConfig/SET_MEDIA_TOKEN';

export const setEnableLinkfify = createAction(SET_ENABLE_LINKIFY, instanceId => {
  return !WP_BLOGS.includes(instanceId);
});
export const setMediaToken = createAction(SET_MEDIA_TOKEN);

export const fetchMediaToken = () => {
  return (dispatch, _, { request }) => {
    const promise = request(`/_api/media/credentials/${MEDIA_TYPE_IMAGE}`);
    return promise
      .then(
        credentials => dispatch(setMediaToken(credentials.mediaToken)),
        () => {},
      )
      .then(() => promise);
  };
};
