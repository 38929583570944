import { CLEAR_POSTS } from '../../actions/clear-posts';
import { FETCH_CATEGORY_POSTS_SUCCESS, FETCH_CATEGORY_POSTS_REQUEST } from '../../actions/fetch-category-posts';
import { FETCH_TAG_POSTS_SUCCESS, FETCH_TAG_POSTS_REQUEST } from '../../actions/fetch-tag-posts';
import { FETCH_FEED_POSTS_SUCCESS, FETCH_FEED_POSTS_REQUEST } from '../../actions/fetch-feed-posts';
import { FETCH_POSTS_SUCCESS, FETCH_POSTS_REQUEST } from '../../actions/fetch-posts';
import { FETCH_USER_POSTS_SUCCESS, FETCH_USER_POSTS_REQUEST } from '../../actions/fetch-posts-by-user';
import { FETCH_COMMENTS_SUCCESS, FETCH_COMMENTS_REQUEST } from '../comments/fetch-comments';
import { FETCH_COMMENT_SUCCESS } from '../comments/fetch-comment';
import { CREATE_COMMENT_SUCCESS } from '../comments/create-comment';
import { DELETE_COMMENT_SUCCESS } from '../comments/delete-comment';
import { DELETE_POST_SUCCESS } from '../../actions/delete-post';
import {
  POST_ENTITY_TYPES,
  ENTITY_TYPE_POSTS,
  ENTITY_TYPE_COMMENTS,
  ENTITY_TYPE_MANAGE_POSTS_PUBLISHED,
  ENTITY_TYPE_MANAGE_POSTS_DRAFTS,
} from '@wix/communities-blog-client-common';
import { PUBLISH_POST_SUCCESS } from '../../actions/publish-post';
import { CREATE_POST_DRAFT_SUCCESS } from '../../actions/create-post-draft';
import { CLEAR_COMMENTS } from '../comments/clear-comments';
import { PAGINATION_LOAD_PAGE } from './pagination-actions';
import {
  INITIAL_STATE,
  withEntities,
  withCurrentPage,
  withInitialState,
  withEntityInHead,
  withEntityInTail,
  withoutEntity,
} from './pagination-old-utils';

const paginationReducerOld = (state = INITIAL_STATE, { type, payload, meta = {} } = {}) => {
  switch (type) {
    // Pagination middleware
    case PAGINATION_LOAD_PAGE:
      return {
        ...state,
        ...withCurrentPage({ state, entityType: payload.entityType, currentPage: payload.page }),
      };
    // POSTS
    case CLEAR_POSTS:
      return {
        ...state,
        ...withInitialState({ state, entityTypes: POST_ENTITY_TYPES }),
      };
    case FETCH_USER_POSTS_REQUEST:
    case FETCH_FEED_POSTS_REQUEST:
    case FETCH_POSTS_REQUEST:
    case FETCH_CATEGORY_POSTS_REQUEST:
    case FETCH_TAG_POSTS_REQUEST:
      return {
        ...state,
        ...withCurrentPage({ state, entityType: payload.entityType || ENTITY_TYPE_POSTS, currentPage: payload.page }),
      };
    case FETCH_USER_POSTS_SUCCESS:
    case FETCH_FEED_POSTS_SUCCESS:
    case FETCH_POSTS_SUCCESS:
    case FETCH_CATEGORY_POSTS_SUCCESS:
    case FETCH_TAG_POSTS_SUCCESS:
      return {
        ...state,
        ...withEntities({ state, entities: payload, ...meta, entityType: meta.entityType || ENTITY_TYPE_POSTS }),
      };
    case CREATE_POST_DRAFT_SUCCESS:
      return {
        ...state,
        ...withEntityInHead({ state, entityType: ENTITY_TYPE_MANAGE_POSTS_DRAFTS, entityId: payload._id }),
      };
    case PUBLISH_POST_SUCCESS:
      return {
        ...state,
        ...withoutEntity({ state, entityTypes: [ENTITY_TYPE_MANAGE_POSTS_DRAFTS], entityId: payload._id }),
        ...withEntityInHead({ state, entityType: ENTITY_TYPE_MANAGE_POSTS_PUBLISHED, entityId: payload._id }),
      };
    case DELETE_POST_SUCCESS:
      return {
        ...state,
        ...withoutEntity({ state, entityTypes: POST_ENTITY_TYPES, entityId: payload }),
      };

    // COMMENTS
    case CLEAR_COMMENTS:
      return {
        ...state,
        ...withInitialState({ state, entityTypes: [ENTITY_TYPE_COMMENTS] }),
      };
    case FETCH_COMMENTS_REQUEST:
      return {
        ...state,
        ...withCurrentPage({ state, entityType: ENTITY_TYPE_COMMENTS, currentPage: payload.page }),
      };
    case FETCH_COMMENTS_SUCCESS:
      return {
        ...state,
        ...withEntities({ state, entities: payload.comments, ...meta, entityType: ENTITY_TYPE_COMMENTS }),
      };
    case CREATE_COMMENT_SUCCESS:
      return {
        ...state,
        ...withEntityInTail({ state, entityType: ENTITY_TYPE_COMMENTS, entityId: payload._id }),
      };
    case FETCH_COMMENT_SUCCESS:
      return {
        ...state,
        ...withEntityInHead({ state, entityType: ENTITY_TYPE_COMMENTS, entityId: payload.comment._id }),
      };
    case DELETE_COMMENT_SUCCESS:
      return {
        ...state,
        ...withoutEntity({ state, entityTypes: [ENTITY_TYPE_COMMENTS], entityId: payload._id }),
      };
    default:
      return state;
  }
};

export default paginationReducerOld;
